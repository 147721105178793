/**
 * @api.ts
 * Provides methods through which to make requests to the API.
 */
import { toast } from 'react-toastify';
import 'url-polyfill';
import 'whatwg-fetch';

import { INCOMPLETE_COMPANY, INCOMPLETE_USER } from '../../shared/constants';
import { castle } from '@ReactComponents/../utils/castle';

const request = (url: string, options: RequestInit = {}) => {
  options.credentials = 'same-origin';

  if (castle) {
    castle.createRequestToken().then( (requestToken: string) => {
      (options.headers as Record<string, string>)['X-Castle-Request-Token'] = requestToken;
    });
  }

  return fetch(url, options).then((response) => {
    if (response.ok) {
      return Promise.resolve(response);
    }
    return response.text().then((text) => {
      switch (text) {
        case INCOMPLETE_USER:
          window.location.href = '/welcomeUser';
          break;
        case INCOMPLETE_COMPANY:
          window.location.href = '/welcomeCompany';
          break;
      }
      throw new Error(text || response.statusText);
    });
  });
};

export class Api {
  private static getCompanyId = () => {
    if (window.companyId) {
      return window.companyId;
    } else if (window.ORG) {
      return window.ORG.id;
    }

    return '';
  };

  static get(urlString: string, includes?: string[]) {
    const url = new URL(urlString, location.origin);
    if (includes) {
      includes.forEach((inclusion) => url.searchParams.append(`include[${inclusion}]`, ''));
    }
    const companyId = Api.getCompanyId();
    return request(url.toString(), {
      headers: {
        companyid: companyId
      }
    });
  }

  static constructRequest<T>(method: string, url: string, body?: T, injectedOptions?: Partial<RequestInit>) {
    const companyId = Api.getCompanyId();
    const headers: HeadersInit = {
      'X-CSRF-Token': (window as any).CSRF,
      companyId: companyId
    };

    const options: RequestInit = {
      method: method,
      headers: headers,
      ...injectedOptions
    };

    if (body) {
      options.body = JSON.stringify(body);
      (options.headers as Record<string, string>)['Content-Type'] = 'application/json; charset=utf-8';
      (options.headers as Record<string, string>).Accept = 'application/json, text/plain, */*';
    }

    return request(url, options);
  }

  static responseToJSON<T>(response: Promise<Response>): Promise<T> {
    return response.then((raw) => raw.json());
  }

  static responseToJSONAndToastError<T>(response: Promise<Response>): Promise<T> {
    return Api.responseToJSON<T>(response).catch((error) => {
      try {
        const data = JSON.parse(error.message);
        toast.error(data.error);
      } catch {
        toast.error(error.message);
      }
      throw new Error(error.message);
    });
  }

  static responseWithToastError<T>(response: Promise<T>): Promise<T> {
    return response.catch((error) => {
      try {
        const data = JSON.parse(error.message);
        toast.error(data.error);
      } catch {
        toast.error(error.message);
      }
      throw new Error(error.message);
    });
  }

  static toastError<T>(error: Error): Promise<T> {
    try {
      const data = JSON.parse(error.message);
      toast.error(data.error);
    } catch {
      toast.error(error.message);
    }
    throw new Error(error.message);
  }

  static put<T>(url: string, body?: T, options?: Partial<RequestInit>) {
    return Api.constructRequest('PUT', url, body, options);
  }

  static post<T>(url: string, body?: T, options?: Partial<RequestInit>) {
    return Api.constructRequest('POST', url, body, options);
  }

  static postFile<T extends FormData>(url: string, body: T) {
    const companyId = Api.getCompanyId();
    const headers: HeadersInit = {
      'X-CSRF-Token': (window as any).CSRF,
      companyId: companyId
    };

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      body: body
    };
    return request(url, options);
  }

  static patch<T>(url: string, body?: T, options?: Partial<RequestInit>) {
    return Api.constructRequest('PATCH', url, body, options);
  }

  static delete<T>(url: string, body?: T, options?: Partial<RequestInit>) {
    return Api.constructRequest('DELETE', url, body, options);
  }

  static parseFilters(params: Record<string, any>) {
    return Object.keys(params)
      .map((field: string) => {
        const value = params[field];

        if (value === undefined || (value && value.length === 0)) {
          return;
        }

        // value can be: null, number, non-empty arrays, non-empty strings, or any objects (empty or not)
        return {
          field,
          value
        };
      })
      .filter(Boolean);
  }
}
