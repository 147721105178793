import * as React from 'react';

import { Button, Grid, Link, TextField } from '@emburse/embark-core';

import { EmburseAccountsLeftContentContainer } from '@ReactComponents/Shared/Account/EmburseAccountsLeftContentContainer';

import { SamlEmailVerificationApi } from '@ClientServices/api/samlEmailVerificationApi';

import { AccountIconWithEmail } from './AccountIconWithEmail';

interface IRedesignedLoginFormProps {
  csrf: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const RedesignedLoginForm: React.FC<IRedesignedLoginFormProps> = ({ csrf, onSubmit }) => {
  const [emailFieldEnabled, setEmailFieldEnabled] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [emailVerificationLoading, setEmailVerificationLoading] = React.useState(false);

  const [showPasswordField, setShowPasswordField] = React.useState(false);

  const handleEmailChange = async (e: any) => {
    e.preventDefault();

    if (email) {
      setEmailFieldEnabled(false);
      setEmailVerificationLoading(true);
      await SamlEmailVerificationApi.checkIfUserHasSSO(email, csrf);
      setEmailVerificationLoading(false);
      setShowPasswordField(true);
    }
  };

  const renderEmailField = () => {
    if (emailFieldEnabled) {
      return (
        <TextField
          id="emailField"
          type="email"
          name="email"
          label="Enter email"
          autoFocus
          fullWidth
          disabled={!emailFieldEnabled}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          required
          data-testid="emailField"
        />
      );
    }

    return (
      <>
        <input type="hidden" name="email" value={email} />
        <Grid container direction={'row'} justifyContent={'space-between'}>
          <Grid item direction={'row'} alignItems={'center'}>
            <AccountIconWithEmail className="jc-fe" email={email} />
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="text"
              onClick={() => {
                setEmailFieldEnabled(true);
                setShowPasswordField(false);
              }}
              data-testid="editEmailButton"
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderLogInButton = () => {
    if (emailFieldEnabled) {
      return (
        <Button size="medium" fullWidth onClick={handleEmailChange} isLoading={emailVerificationLoading} data-testid="continueButton">
          Continue
        </Button>
      );
    }

    return (
      <Button id="loginButton" className="login" type="submit" size="medium" fullWidth>
        Log In
      </Button>
    );
  };

  function renderLoginForm() {
    return (
      <form method="POST" action="/login" id="login" autoComplete="off" onSubmit={onSubmit}>
        <input type="hidden" name="_csrf" value={csrf} />
        <Grid container direction={'column'} spacing={2} style={{ width: '400px' }}>
          <Grid item>{renderEmailField()}</Grid>
          {showPasswordField && (
            <Grid item>
              <TextField id="passwordField" type="password" name="password" label="Enter Password" autoComplete="off" fullWidth required />
            </Grid>
          )}
          <Grid item>{renderLogInButton()}</Grid>
          {showPasswordField && (
            <Grid item>
              <div className="forgotContainer">
                <Link href="/reset_password">Forgot Password?</Link>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }

  return (
    <EmburseAccountsLeftContentContainer title="Sign In">
      <Grid item>{renderLoginForm()}</Grid>
    </EmburseAccountsLeftContentContainer>
  );
};
