export const BETA_USERS = 'beta_users';
export const ANNUAL_CONTRACT = 'annual_contract';
export const PLAN_1 = 'plan_1';
export const PLAN_2 = 'plan_2';
export const NON_PROFIT = 'non_profit';
export const ONE_FREE_MONTH = 'one_free_month';
export const TWO_FREE_MONTHS = 'two_free_months';
export const THREE_FREE_MONTHS = 'three_free_months';
export const THREE_USER_MINIMUM_ONE_MONTH_FREE = 'three_user_minimum_one_month_free';
export const THREE_USER_MINIMUM_TWO_MONTHS_FREE = 'three_user_minimum_two_months_free';
export const THREE_USER_MINIMUM_THREE_MONTHS_FREE = 'three_user_minimum_three_months_free';
export const THREE_USER_MINIMUM_ONE_MONTH_FREE_NINE_DOLLARS = 'three_user_minimum_one_month_free_nine_dollars';
export const THREE_USER_MINIMUM_TWO_MONTHS_FREE_NINE_DOLLARS = 'three_user_minimum_two_months_free_nine_dollars';
export const THREE_USER_MINIMUM_THREE_MONTHS_FREE_NINE_DOLLARS = 'three_user_minimum_three_months_free_nine_dollars';
export const THREE_USER_MINIMUM_ONE_MONTH_FREE_EIGHTEEN_DOLLARS = 'three_user_minimum_one_month_free_eighteen_dollars';
export const TWO_USER_MINIMUM_ONE_MONTH_FREE_EIGHTEEN_DOLLARS = 'two_user_minimum_one_month_free_eighteen_dollars';
export const TWO_USER_MINIMUM_ONE_MONTH_FREE_NINE_DOLLARS = 'two_user_minimum_one_month_free_nine_dollars';
export const TWO_USER_MINIMUM_TWO_MONTHS_FREE_NINE_DOLLARS = 'two_user_minimum_two_months_free_nine_dollars';
export const TWO_USER_MINIMUM_THREE_MONTHS_FREE_NINE_DOLLARS = 'two_user_minimum_three_months_free_nine_dollars';
export const SPEND_STARTER_FREE = 'spend_starter_free';
export const SPEND_ENTERPRISE = 'spend_enterprise';
export const SPEND_DEMO = 'spend_demo';
export const SPEND_PLUS_TWO_HUNDRED_FIFTY_DOLLARS = 'spend_plus_two_hundred_fifty_dollars';
export const SPEND_CARD_FEED = 'spend_card_feed';
export const SPEND_PLUS = 'spend_plus';
export const SPEND_CARD_FEED_PLUS = 'spend_card_feed_plus';
export const SPEND_ANNUAL_CONTRACT = 'spend_annual_contract';
export const SPEND_BASIC_ANNUAL_CONTRACT = 'spend_basic_annual_contract';
export const SPEND_BASIC_EIGHT_DOLLARS = 'spend_basic_eight_dollars';
export const SPEND_PLUS_TWELVE_DOLLARS = 'spend_plus_twelve_dollars';
export const AMEX_STARTER_FREE = 'amex_starter_free';
export const AMEX_PROFESSIONAL_SIX_DOLLARS = 'amex_professional_six_dollars';
export const AMEX_STARTER_FREE_STRIPE = 'amex_starter_free_stripe';
export const AMEX_PROFESSIONAL_SIX_DOLLARS_STRIPE = 'amex_professional_six_dollars_stripe';

export const BILLING_PLAN_VALUES = [
  BETA_USERS,
  ANNUAL_CONTRACT,
  PLAN_1,
  PLAN_2,
  NON_PROFIT,
  ONE_FREE_MONTH,
  TWO_FREE_MONTHS,
  THREE_FREE_MONTHS,
  THREE_USER_MINIMUM_ONE_MONTH_FREE,
  THREE_USER_MINIMUM_TWO_MONTHS_FREE,
  THREE_USER_MINIMUM_THREE_MONTHS_FREE,
  THREE_USER_MINIMUM_ONE_MONTH_FREE_NINE_DOLLARS,
  THREE_USER_MINIMUM_TWO_MONTHS_FREE_NINE_DOLLARS,
  THREE_USER_MINIMUM_THREE_MONTHS_FREE_NINE_DOLLARS,
  THREE_USER_MINIMUM_ONE_MONTH_FREE_EIGHTEEN_DOLLARS,
  TWO_USER_MINIMUM_ONE_MONTH_FREE_EIGHTEEN_DOLLARS,
  TWO_USER_MINIMUM_ONE_MONTH_FREE_NINE_DOLLARS,
  TWO_USER_MINIMUM_TWO_MONTHS_FREE_NINE_DOLLARS,
  TWO_USER_MINIMUM_THREE_MONTHS_FREE_NINE_DOLLARS,
  SPEND_DEMO,
  SPEND_STARTER_FREE,
  SPEND_ENTERPRISE,
  SPEND_ANNUAL_CONTRACT,
  SPEND_BASIC_ANNUAL_CONTRACT,
  SPEND_PLUS_TWO_HUNDRED_FIFTY_DOLLARS,
  SPEND_CARD_FEED,
  SPEND_PLUS,
  SPEND_CARD_FEED_PLUS,
  SPEND_BASIC_EIGHT_DOLLARS,
  SPEND_PLUS_TWELVE_DOLLARS,
  AMEX_STARTER_FREE,
  AMEX_PROFESSIONAL_SIX_DOLLARS,
  AMEX_STARTER_FREE_STRIPE,
  AMEX_PROFESSIONAL_SIX_DOLLARS_STRIPE
];

export const MANUAL_CONTRACTS = [ANNUAL_CONTRACT, SPEND_STARTER_FREE, SPEND_ENTERPRISE, AMEX_STARTER_FREE];

export const ALLOW_CARD_TRANSACTION_APPROVAL_CONTRACTS = [
  BETA_USERS,
  ANNUAL_CONTRACT,
  SPEND_DEMO,
  SPEND_STARTER_FREE,
  SPEND_ENTERPRISE,
  SPEND_ANNUAL_CONTRACT,
  SPEND_BASIC_ANNUAL_CONTRACT,
  SPEND_PLUS_TWO_HUNDRED_FIFTY_DOLLARS,
  SPEND_CARD_FEED,
  SPEND_PLUS,
  SPEND_CARD_FEED_PLUS,
  SPEND_BASIC_EIGHT_DOLLARS,
  SPEND_PLUS_TWELVE_DOLLARS,
  AMEX_STARTER_FREE,
  AMEX_PROFESSIONAL_SIX_DOLLARS,
  AMEX_STARTER_FREE_STRIPE,
  AMEX_PROFESSIONAL_SIX_DOLLARS_STRIPE
];
export type BILLING_PLAN_TYPE = typeof BILLING_PLAN_VALUES[number];

export enum AMEX_BILLING_PLAN_TYPE {
  FREE_PLAN = 'amex_starter_free',
  PRO_PLAN = 'amex_professional_six_dollars'
}

export enum AMEX_BILLING_STRIPE_PLAN_TYPE {
  FREE_PLAN = 'amex_starter_free_stripe',
  PRO_PLAN = 'amex_professional_six_dollars_stripe'
}

export enum SPEND_BILLING_PLAN_TYPE {
  BASIC_PLAN = 'spend_basic_eight_dollars',
  PRO_PLAN = 'spend_plus_twelve_dollars'
}

// Billing plans that have upgrade path
export const UPGRADABLE_PLANS = {
  [AMEX_BILLING_PLAN_TYPE.FREE_PLAN]: AMEX_BILLING_PLAN_TYPE.PRO_PLAN,
  [AMEX_BILLING_STRIPE_PLAN_TYPE.FREE_PLAN]: AMEX_BILLING_STRIPE_PLAN_TYPE.PRO_PLAN,
  [SPEND_BILLING_PLAN_TYPE.BASIC_PLAN]: SPEND_BILLING_PLAN_TYPE.PRO_PLAN
};

export type BILLING_METHODS = 'stripe' | 'bank' | 'external';

export const AMEX_STARTER_FREE_PER_USER_AMOUNT = 'Free';
export const AMEX_PROFESSIONAL_SIX_DOLLARS_PER_USER_AMOUNT = '$6';

export const SPEND_BASIC_EIGHT_DOLLARS_PER_USER_AMOUNT = '$8';
export const SPEND_BASIC_EIGHT_DOLLARS_MINIMUM_USERS = '25 user minimum ($200)';

export const SPEND_PLUS_TWELVE_DOLLARS_PER_USER_AMOUNT = '$12';
export const SPEND_PLUS_TWELVE_DOLLARS_MINIMUM_USERS = '25 user minimum ($300)';
